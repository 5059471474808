import consumer from "./consumer"

var roomChannel = consumer.subscriptions.create("RoomChannel", {
  connected() {
    console.log("IAM Connected to room channel...yes!")
    this.appear()
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log(data);
    //console.log(data.message);
    //console.log(data.init);
    //console.log(data.message);

		var wall = document.getElementById('wall');
    if( Array.isArray(data.messages) ){
      data.messages.forEach(function (item,index){
        //console.log(item,index);
        wall.innerHTML = "<tr><td>" + item + "</td></tr>" + wall.innerHTML;
      });
    }
    else{
      console.log("no xread");
      //wall.innerHTML += "<h2>" + data.title + "</h2>";

      //wall.innerHTML = "<p>" + data.message + "</p>" + wall.innerHTML;
      wall.innerHTML = "<tr><td>" + data.message + "</td></tr>" + wall.innerHTML;
    }

    // Called when there's incoming data on the websocket for this channel
  },
  appear() {
    this.perform("appear", {init: 1})
  }
});

